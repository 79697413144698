var modelCache = {};

var _document = typeof document !== 'undefined' ? document : {
  addEventListener: function addEventListener() {},
  removeEventListener: function removeEventListener() {}
};

var WixMock = {
  closeWindow: function closeWindow() {},
  addEventListener: function addEventListener(event, callback) {
    _document.addEventListener(event, callback);
  },
  removeEventListener: function removeEventListener(event, callback) {
    _document.removeEventListener(event, callback);
  },
  stylesMock: {
    fonts: {
      testKey: {
        cssFontFamily: '1111'
      }
    },
    numbers: {},
    colors: {},
    booleans: {}
  },
  colorMock: {
    colors: {
      testColorKey: 'color-1'
    }
  },
  getBoundingRectAndOffsets: function getBoundingRectAndOffsets(callback) {
    callback({
      offsets: {
        x: 0,
        y: 0
      }
    });
  },
  getCurrentPageId: function getCurrentPageId(callback) {
    callback('');
  },
  Utils: {
    navigateToSection: function navigateToSection() {},
    getOrigCompId: function getOrigCompId() {},
    getCompId: function getCompId() {
      return null;
    },
    getViewMode: function getViewMode() {},
    getDemoMode: function getDemoMode() {},
    getInstance: function getInstance() {
      return null;
    },
    getInstanceValue: function getInstanceValue() {
      return null;
    },
    getInstanceId: function getInstanceId() {
      return null;
    },
    getBusinessId: function getBusinessId() {
      return '';
    },
    isOverEditor: function isOverEditor() {
      return false;
    },
    getSectionUrl: function getSectionUrl(sectionIdentifier, callback) {
      setTimeout(function () {
        callback('https://mock_section_url');
      }, 0);
    },
    getSiteOwnerId: function getSiteOwnerId() {
      return '';
    },
    getUid: function getUid() {
      return '';
    },
    Media: {
      getResizedImageUrl: function getResizedImageUrl(relativeUrl, width, height) {
        return '/images/test-' + relativeUrl + '-' + width + '-' + height + '.jpg';
      }
    }
  },
  setHeight: function setHeight() {},
  UI: {
    create: function create() {
      return {};
    },
    initialize: function initialize() {},
    onChange: function onChange(key, func) {
      _document.addEventListener(key, function () {
        func(modelCache[key]);
      });
    },
    get: function get(key) {
      return modelCache[key];
    },
    set: function set(key, val) {
      modelCache[key] = val;
    },
    modelCache: modelCache
  },
  Styles: {
    getColorByreference: function getColorByreference() {
      return {
        value: '#123456'
      };
    },
    getSiteColors: function getSiteColors() {
      var arr = [];

      for (var i = 0; i < 31; i++) {
        arr[i] = i;
      }

      return arr;
    },
    getStyleParams: function getStyleParams(callbackFunc) {
      if (callbackFunc) {
        return callbackFunc(WixMock.stylesMock);
      } else {
        return WixMock.stylesMock;
      }
    },
    getStyleParamsByStyleId: function getStyleParamsByStyleId(styleId, pageId, callbackFunc) {
      if (callbackFunc) {
        return callbackFunc(WixMock.stylesMock);
      } else {
        return WixMock.stylesMock;
      }
    },
    restoreParam: function restoreParam() {
      WixMock.stylesMock = {
        fonts: {
          testKey: {
            cssFontFamily: '1111'
          }
        },
        numbers: {},
        colors: {},
        booleans: {}
      };
    },
    setFontParam: function setFontParam(key, value, callback) {
      WixMock.stylesMock.fonts[key] = {
        cssFontFamily: value.value.cssFontFamily
      };

      if (callback) {
        callback();
      }
    },
    setColorParam: function setColorParam(key, value, callback) {
      WixMock.colorMock.colors[key] = value;

      if (callback) {
        callback();
      }
    },
    getSiteTextPresets: function getSiteTextPresets() {
      return {
        Title: {
          editorKey: 'font_0',
          fontFamily: 'fontFamilyTitle',
          lineHeight: '1.4em',
          size: '40px',
          style: 'normal',
          value: 'value',
          weight: 'normal'
        }
      };
    },
    getEditorFonts: function getEditorFonts() {
      return [{
        language: 'hebrew',
        fonts: [{
          cssFontFamily: 'fontFamilyTitle0',
          fontFamily: 'fontFamilyTitle0',
          genericFamily: 'cursive0',
          permissions: 'all0',
          provider: 'google0',
          spriteIndex: 132
        }]
      }, {
        language: 'latin',
        fonts: [{
          cssFontFamily: 'fontFamilyTitle',
          fontFamily: 'fontFamilyTitle',
          genericFamily: 'cursive',
          permissions: 'all',
          provider: 'google',
          spriteIndex: 154
        }]
      }, {
        language: 'arabic',
        fonts: [{
          cssFontFamily: 'fontFamilyTitleArabic',
          fontFamily: 'fontFamilyTitleArabic',
          genericFamily: 'cursiveArabic',
          permissions: 'allArabic',
          provider: 'googleArabic',
          spriteIndex: 160
        }]
      }];
    },
    getStyleId: function getStyleId(callback) {
      callback('mock-style-id');
    }
  },
  Settings: {
    openModal: function openModal(url, width, height, name, callback) {
      callback();
    },
    triggerSettingsUpdatedEvent: function triggerSettingsUpdatedEvent() {},
    getSiteInfo: function getSiteInfo(callback) {
      return callback({
        baseUrl: 'www.test.boost.co.il'
      });
    },
    getDashboardAppUrl: function getDashboardAppUrl(func) {
      func.call();
    },
    refreshApp: function refreshApp() {
      return '';
    },
    setExternalId: function setExternalId() {},
    addComponent: function addComponent() {},
    MediaType: {
      IMAGE: 'photos',
      BACKGROUND: 'backgrounds',
      AUDIO: 'audio',
      DOCUMENT: 'documents',
      SWF: 'swf',
      SECURE_MUSIC: 'secure_music',
      VIDEO: 'video',
      SHAPE: 'shape',
      MUSIC: 'music',
      CLIPART: 'clipart',
      BG_VIDEO: 'bg_video',
      ICON_DOCUMENT: 'icon_document',
      ICON_SOCIAL: 'bg_social',
      ICON_FAVICON: 'bg_favicon',
      MUSIC_PRO: 'secure_music',
      IMAGE_PRO: 'secure_picture',
      FLASH: 'swf',
      BG_IMAGE: 'backgrounds'
    }
  },
  Events: {
    PUBLIC_DATA_CHANGED: 'PUBLIC_DATA_CHANGED',
    SETTINGS_UPDATED: 'SETTINGS_UPDATED',
    EDIT_MODE_CHANGE: 'EDIT_MODE_CHANGE',
    STYLE_PARAMS_CHANGE: 'STYLE_PARAMS_CHANGE',
    THEME_CHANGE: 'THEME_CHANGE',
    PAGE_NAVIGATION_IN: 'PAGE_NAVIGATION_IN',
    PAGE_NAVIGATION_OUT: 'PAGE_NAVIGATION_OUT'
  },
  Theme: {
    BARE: 'bare'
  },
  scrollTo: function scrollTo() {},
  scrollBy: function scrollBy() {},
  getSiteInfo: function getSiteInfo() {},
  Data: {
    Public: {
      get: function get(key, jsonObj, callback) {
        callback({
          pg: 1
        });
      }
    }
  },
  Features: {
    isSupported: function isSupported() {// isSupported: function (feature, callback){
      // callback(true);
    },
    Types: {
      ADD_COMPONENT: 'ADD_COMPONENT'
    }
  },
  PubSub: {
    subscribe: function subscribe() {},
    publish: function publish() {}
  },
  getSitePages: function getSitePages(callback) {
    if (typeof callback === 'function') {
      callback([]);
    }
  },
  replaceSectionState: function replaceSectionState() {},
  Performance: {
    applicationLoaded: function applicationLoaded() {},
    applicationLoadingStep: function applicationLoadingStep() {}
  },
  Activities: {
    postActivity: function postActivity() {},
    Type: {
      SOCIAL_TRACK: 'SOCIAL_TRACK'
    }
  }
};
export default WixMock;