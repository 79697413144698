import { experimentsWrapper } from '../sdk/experimentsWrapper';
import { renderUtils } from '../utils/renderUtils';
import { resizeMediaUrlWithWatermark as resizeMediaUrlWithWatermarkOld, resizeMediaUrl as resizeMediaUrlOld } from './itemResizerOld';
import { resizeMediaUrlWithWatermark as resizeMediaUrlWithWatermarkNew, resizeMediaUrl as resizeMediaUrlNew } from './itemResizerNew';
export var resizeMediaUrl = function resizeMediaUrl() {
  return shouldUseMultipleUrls() ? resizeMediaUrlNew.apply(void 0, arguments) : resizeMediaUrlOld.apply(void 0, arguments);
};
export var resizeMediaUrlWithWatermark = function resizeMediaUrlWithWatermark() {
  return shouldUseMultipleUrls() ? resizeMediaUrlWithWatermarkNew.apply(void 0, arguments) : resizeMediaUrlWithWatermarkOld.apply(void 0, arguments);
};

var shouldUseMultipleUrls = function shouldUseMultipleUrls() {
  var generalExperiment = experimentsWrapper.getExperimentBoolean('specs.pro-gallery.useMultipleImageUrls');
  var editorExperiment = experimentsWrapper.getExperimentBoolean('specs.pro-gallery.useMultipleImageUrls-editor');
  var should = false;

  if (!renderUtils.isEditor() && generalExperiment) {
    should = true;
  } else if (renderUtils.isEditor() && editorExperiment) {
    should = true;
  }

  return should;
};