var placements = {
  SHOW_ON_HOVER: 'SHOW_ON_HOVER',
  SHOW_BELOW: 'SHOW_BELOW',
  SHOW_ABOVE: 'SHOW_ABOVE',
  SHOW_NOT_ON_HOVER: 'SHOW_NOT_ON_HOVER',
  SHOW_ALWAYS: 'SHOW_ALWAYS',
  DONT_SHOW: 'DONT_SHOW',
  ALTERNATE_HORIZONTAL: 'ALTERNATE_HORIZONTAL',
  ALTERNATE_VERTICAL: 'ALTERNATE_VERTICAL'
};
var infoBehaviourOnHover = {
  APPEARS: 'APPEARS',
  DISAPPEARS: 'DISAPPEARS',
  NO_CHANGE: 'NO_CHANGE'
};
var expandInfoPositions = {
  SIDE: 'SIDE',
  BOTTOM: 'BOTTOM'
};
var loadingMode = {
  BLUR: 'BLUR',
  COLOR: 'COLOR'
};
var loadingWithColorMode = {
  MAIN_COLOR: 'MAIN_COLOR',
  PICKED_COLOR: 'PICKED_COLOR'
};
var imageRatioType = {
  FIXED: 'FIXED',
  RESPONSIVE: 'RESPONSIVE'
};
var dimensions = {
  SIDE_BAR_WIDTH: 430,
  STORE_SIDE_BAR_WIDTH: 560,
  MOBILE_PADDING: 120,
  NO_PADDING: 0
};
var scrollAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  FADE_IN: 'FADE_IN',
  GRAYSCALE: 'GRAYSCALE',
  SLIDE_UP: 'SLIDE_UP',
  EXPAND: 'EXPAND',
  SHRINK: 'SHRINK',
  ZOOM_OUT: 'ZOOM_OUT',
  ONE_COLOR: 'ONE_COLOR'
};
var overlayAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  FADE_IN: 'FADE_IN',
  EXPAND: 'EXPAND',
  SLIDE_UP: 'SLIDE_UP',
  SLIDE_RIGHT: 'SLIDE_RIGHT'
};
var imageHoverAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  ZOOM_IN: 'ZOOM_IN',
  BLUR: 'BLUR',
  GRAYSCALE: 'GRAYSCALE',
  SHRINK: 'SHRINK',
  INVERT: 'INVERT',
  COLOR_IN: 'COLOR_IN',
  DARKENED: 'DARKENED'
};
var mobileSwipeAnimations = {
  EXPAND: 'EXPAND',
  FADE: 'FADE',
  CAROUSEL: 'CAROUSEL',
  CROSSFADE: 'CROSSFADE'
};
var expandAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  EXPAND: 'EXPAND',
  FADE_IN: 'FADE_IN',
  ZOOM: 'ZOOM'
};
var dataSavedState = {
  SAVED: 'SAVED',
  PUBLISHED: 'PUBLISHED'
};
export var SENTRY_DSN = 'https://53ff996300e94e7983242fc53bc63c5f@sentry.io/1360936';
var infoType = {
  NO_BACKGROUND: 'NO_BACKGROUND',
  ATTACHED_BACKGROUND: 'ATTACHED_BACKGROUND',
  SEPARATED_BACKGROUND: 'SEPARATED_BACKGROUND',
  DONT_SHOW: 'DONT_SHOW'
};
var calculationOptions = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL'
};
var slideAnimation = {
  SCROLL: 'SCROLL',
  FADE: 'FADE'
};
export default {
  placements: placements,
  infoBehaviourOnHover: infoBehaviourOnHover,
  loadingMode: loadingMode,
  loadingWithColorMode: loadingWithColorMode,
  imageRatioType: imageRatioType,
  dimensions: dimensions,
  scrollAnimations: scrollAnimations,
  overlayAnimations: overlayAnimations,
  imageHoverAnimations: imageHoverAnimations,
  mobileSwipeAnimations: mobileSwipeAnimations,
  expandAnimations: expandAnimations,
  expandInfoPositions: expandInfoPositions,
  infoType: infoType,
  dataSavedState: dataSavedState,
  calculationOptions: calculationOptions,
  slideAnimation: slideAnimation
};