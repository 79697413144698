/* eslint-disable no-debugger */
import window from '../sdk/windowWrapper';
import * as imageSdk from 'image-client-api/dist/imageClientSDK';
import watermarkHelper from '../store/watermarkHelper';
import imageTokenHelper from '../store/imageTokenHelper';
import { noop } from 'lodash';
var WIX_MEDIA_PREFIX = 'https://static.wixstatic.com/media/';
var WIX_MEDIA_DOMAIN = '//static.wixstatic.com';

var isExternalUrl = function isExternalUrl(url) {
  return /(^https?)|(^data)|(^blob)/.test(url);
};

var shouldUseImageClientApi = function shouldUseImageClientApi(item, sharpParams, requiredWidth) {
  var isImageSizeAvailable = !item.isDimensionless;
  var isThumb = sharpParams && sharpParams.blur > 0;
  var isPixelImage = requiredWidth === 1; // const hasWatermark = watermarkHelper.exists;

  return !isPixelImage && isImageSizeAvailable && !isThumb; // && !hasWatermark;
};

var isPosterImage = function isPosterImage(item) {
  if (item && item.dto && item.dto.metaData) {
    var _item$dto = item.dto,
        _item$dto$metaData = _item$dto.metaData,
        posters = _item$dto$metaData.posters,
        type = _item$dto$metaData.type,
        mediaUrl = _item$dto.mediaUrl;

    if (type === 'video') {
      return true;
    }

    if (mediaUrl && posters && Array.isArray(posters)) {
      return posters.some(function (poster) {
        return poster.url === mediaUrl;
      });
    }
  }

  return false;
};

var isWixMediaUrl = function isWixMediaUrl(url) {
  return url.indexOf(WIX_MEDIA_DOMAIN) >= 0;
};

var getWixFilename = function getWixFilename(url) {
  return url.replace(WIX_MEDIA_PREFIX, '');
};

var prefixUrlIfNeeded = function prefixUrlIfNeeded(originalUrl) {
  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else {
    return WIX_MEDIA_PREFIX + originalUrl;
  }
};

var getDevicePixelRatio = function getDevicePixelRatio(item) {
  try {
    if (shouldUseImageClientApi(item)) {
      return 1;
    } else {
      return window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI; // Support for IE10
    }
  } catch (e) {
    return 1;
  }
};

var resizeVideoImp = function resizeVideoImp(item, originalUrl, requiredWidth, requiredHeight) {
  var videoUrl = originalUrl;

  if (item.qualities && item.qualities.length) {
    var suffix = '/';
    var mp4Qualities = item.qualities.filter(function (video) {
      return video.formats[0] === 'mp4';
    }); // search for the first quality bigger that the required one

    if (mp4Qualities.length > 1 && mp4Qualities[0].height > mp4Qualities[1].height) {
      // some have reversed quality order. not sure how or when this happened
      mp4Qualities.reverse();
    }

    for (var quality, q = 0; quality = mp4Qualities[q]; q++) {
      if (quality.height >= requiredHeight || !mp4Qualities[q + 1]) {
        suffix += quality.quality; // e.g. 720p

        for (var format, i = 0; format = quality.formats[i]; i++) {
          videoUrl = window.location.protocol + '//video.wixstatic.com/video/' + item.url + suffix + '/' + format + '/file.' + format;
        }

        break;
      }
    }

    return videoUrl; // const wixQualities = this.qualities.filter(video => video.formats[0] === 'wix');
    // urls.wix = window.location.protocol + '//video.wixstatic.com/video/' + this.url + suffix + '/' + format + '/file.' + format;
  }
};

var resizeUrlImp_manual = function resizeUrlImp_manual(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint) {
  requiredWidth = Math.ceil(requiredWidth);
  requiredHeight = Math.ceil(requiredHeight);
  var requiredRatio = requiredWidth / requiredHeight;
  var showWatermark = watermarkHelper.exists; // assign sharp default parameters

  sharpParams = sharpParams || {}; // calc default quality

  if (!sharpParams.quality) {
    sharpParams.quality = 90;
  } // don't allow quality above 90 till we have proper UI indication


  sharpParams.quality = Math.min(90, sharpParams.quality);

  if (sharpParams.allowUsm === true) {
    sharpParams.usm.usm_a = Math.min(5, Math.max(0, sharpParams.usm.usm_a || 0));
    sharpParams.usm.usm_r = Math.min(128, Math.max(0, sharpParams.usm.usm_r || 0)); // should be max 500 - but it's returning a 404

    sharpParams.usm.usm_t = Math.min(1, Math.max(0, sharpParams.usm.usm_t || 0));
  }

  var focalPointObj = {
    x: 50,
    y: 50
  };

  if (focalPoint && focalPoint[0] >= 0 && focalPoint[1] >= 0) {
    focalPointObj.x = Math.round(focalPoint[0] * 100);
    focalPointObj.y = Math.round(focalPoint[1] * 100);
  }

  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else if (!focalPoint) {
    // todo remove when supporting focal point
    var retUrl = prefixUrlIfNeeded(originalUrl) + '/v1/' + resizeMethod + '/';
    retUrl += 'w_' + requiredWidth;
    retUrl += ',h_' + requiredHeight;

    if (resizeMethod === 'fill') {
      retUrl += ",fp_0." + focalPointObj.x + "_0." + focalPointObj.y;
    } // retUrl += ',al_' + (faces ? 'fs' : 'c');


    retUrl += ',q_' + sharpParams.quality;

    if (sharpParams.blur) {
      retUrl += ',blur_' + sharpParams.blur;
    }

    retUrl += sharpParams.usm && sharpParams.usm.usm_r ? ',usm_' + sharpParams.usm.usm_r.toFixed(2) + '_' + sharpParams.usm.usm_a.toFixed(2) + '_' + sharpParams.usm.usm_t.toFixed(2) : ''; // Important to use this as the last param

    if (showWatermark && !isPosterImage(item)) {
      retUrl += watermarkHelper.strUrl;
    }

    retUrl += '/' + originalUrl;
    retUrl = imageTokenHelper.addToken(retUrl, item);
    return retUrl;
  } else {
    var scale;
    var x;
    var y;
    var orgW;
    var orgH; // find the scale

    if (item.ratio > requiredRatio) {
      // wide image (relative to required ratio
      scale = requiredHeight / item.maxHeight;
      orgW = Math.floor(requiredHeight * item.ratio);
      y = 0;
      x = Math.round(orgW * focalPoint[0] - requiredWidth / 2);
      x = Math.min(orgW - requiredWidth, x);
      x = Math.max(0, x);
    } else {
      // narrow image
      scale = requiredWidth / item.maxWidth;
      orgH = Math.floor(requiredWidth / item.ratio);
      x = 0;
      y = Math.round(orgH * focalPoint[1] - requiredHeight / 2);
      y = Math.min(orgH - requiredHeight, y);
      y = Math.max(0, y);
    } // make sure scale is not lower than needed
    // scale must be higher to prevent cases that there will be white margins (or 404)


    scale = Math.ceil(scale * 100) / 100;

    var _retUrl = prefixUrlIfNeeded(originalUrl) + '/v1/crop/';

    _retUrl += 'w_' + requiredWidth;
    _retUrl += ',h_' + requiredHeight;
    _retUrl += ',x_' + x;
    _retUrl += ',y_' + y;
    _retUrl += ',scl_' + scale.toFixed(2);
    _retUrl += ',q_' + sharpParams.quality;

    if (sharpParams.blur) {
      _retUrl += ',blur_' + sharpParams.blur;
    }

    _retUrl += sharpParams.usm && sharpParams.usm.usm_r ? ',usm_' + sharpParams.usm.usm_r.toFixed(2) + '_' + sharpParams.usm.usm_a.toFixed(2) + '_' + sharpParams.usm.usm_t.toFixed(2) : ''; // Important to use this as the last param

    if (showWatermark && !isPosterImage(item)) {
      _retUrl += watermarkHelper.strUrl;
    }

    _retUrl += '/' + originalUrl;
    _retUrl = imageTokenHelper.addToken(_retUrl, item);
    return _retUrl;
  }
};

var resizeUrlImp_sdk = function resizeUrlImp_sdk(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint) {
  // assign default parameters
  originalUrl = originalUrl || '';
  sharpParams = sharpParams || {}; // calc default quality

  if (sharpParams.quality > 0) {
    // don't allow quality above 90 till we have proper UI indication
    sharpParams.quality = Math.min(90, sharpParams.quality);
  }

  var focalPointObj = {
    x: 50,
    y: 50
  };

  if (focalPoint && focalPoint[0] >= 0 && focalPoint[1] >= 0) {
    focalPointObj.x = Math.round(focalPoint[0] * 100);
    focalPointObj.y = Math.round(focalPoint[1] * 100);
  }

  if (sharpParams.allowUsm === true && sharpParams.usm) {
    sharpParams.usm.usm_a = Math.min(5, Math.max(0, sharpParams.usm.usm_a || 0));
    sharpParams.usm.usm_r = Math.min(128, Math.max(0, sharpParams.usm.usm_r || 0)); // should be max 500 - but it's returning a 404

    sharpParams.usm.usm_t = Math.min(1, Math.max(0, sharpParams.usm.usm_t || 0));
  } else {
    sharpParams.usm = {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0
    };
  }

  if (isExternalUrl(originalUrl) && !isWixMediaUrl(originalUrl)) {
    return originalUrl;
  } else {
    var resizer = noop;

    if (resizeMethod === 'fit') {
      // function getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
      resizer = imageSdk.getScaleToFitImageURL;
    } else {
      // function getScaleToFillImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
      resizer = imageSdk.getScaleToFillImageURL;
    }
    /**
     * the transform options
     * @typedef  {object}   ImageTransformOptions
     * @property {boolean}  [progressive]               image transform progressive
     * @property {number}   [quality]                   image transform quality
     * @property {string}   [watermark]                 image watermark id
     * @property {object}   [unsharpMask]               unsharpMask filter
     * @property {number}   [unsharpMask.radius]        unsharpMask radius
     * @property {number}   [unsharpMask.amount]        unsharpMask amount
     * @property {number}   [unsharpMask.threshold]     unsharpMask threshold
     */


    var options = {};

    if (sharpParams.quality > 0) {
      options.quality = sharpParams.quality;
    }

    if (sharpParams.blur > 0) {
      options.filters = {
        blur: sharpParams.blur
      };
    }

    if (focalPointObj) {
      options.focalPoint = focalPointObj;
    }

    if (sharpParams && sharpParams.usm) {
      options.unsharpMask = {
        radius: parseFloat(sharpParams.usm.usm_r),
        amount: parseFloat(sharpParams.usm.usm_a),
        threshold: parseFloat(sharpParams.usm.usm_t)
      };
    }

    if (!isPosterImage(item)) {
      if (watermarkHelper.str) {
        options.watermark = watermarkHelper.str;
      } else if (item.metaData && item.metaData.watermarkStr) {
        options.watermark = item.metaData.watermarkStr;
      }
    }

    var retUrl = resizer(getWixFilename(originalUrl), item.maxWidth, item.maxHeight, requiredWidth, requiredHeight, options);
    retUrl = imageTokenHelper.addToken(retUrl, item);
    return retUrl;
  }
};

var resizeUrlImp = function resizeUrlImp(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint, watermark) {
  requiredWidth = Math.ceil(requiredWidth * getDevicePixelRatio(item));
  requiredHeight = Math.ceil(requiredHeight * getDevicePixelRatio(item));
  var hasWatermark;
  var hasImageToken = item.dto.imageToken || item.dto.token;

  if (watermark && !hasImageToken) {
    watermarkHelper.setWatermark(watermark);
    hasWatermark = true;
  } // remove resizing parameters if exists


  var resizingParamerterRegex = /\/v\d\/(fill|fit)\/w_\d*,h_\d*/;
  var resizingParametersPosition = resizingParamerterRegex.exec(originalUrl);

  if (resizingParametersPosition && resizingParametersPosition.index > 0) {
    originalUrl = originalUrl.substr(0, resizingParametersPosition.index);
  }

  if (resizeMethod === 'video') {
    return resizeVideoImp(item, originalUrl, requiredWidth, requiredHeight);
  } else if (resizeMethod === 'full' && !hasWatermark && !hasImageToken) {
    return prefixUrlIfNeeded(originalUrl);
  } else if (shouldUseImageClientApi(item, sharpParams, requiredWidth)) {
    return resizeUrlImp_sdk(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint);
  } else {
    return resizeUrlImp_manual(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint);
  }
};

var resizeMediaUrl = resizeUrlImp;

var resizeMediaUrlWithWatermark = function resizeMediaUrlWithWatermark(watermark) {
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return resizeUrlImp.apply(void 0, args.concat([watermark]));
  };
};

export { resizeMediaUrl, resizeMediaUrlWithWatermark };