var FULLSCREEN_EVENTS = {
  UPDATE_CURRENT_ITEM: 'UPDATE_CURRENT_ITEM',
  CLOSE_FULLSCREEN: 'CLOSE_FULLSCREEN',
  NEED_MORE_ITEMS: 'NEED_MORE_ITEMS',
  TOGGLE_BROWSER_FULLSCREEN: 'TOGGLE_BROWSER_FULLSCREEN',
  NAVIGATE: 'NAVIGATE',
  ON_NEXT_SLIDE: 'ON_NEXT_SLIDE',
  //iFrame only (pro-fullscreen-tpa-wrapper)
  ITEM_CREATED: 'ITEM_CREATED',
  //iFrame only (pro-fullscreen-tpa-wrapper)
  //itemActions
  SHARE_BUTTON_CLICKED: 'SHARE_BUTTON_CLICKED',
  SOCIAL_SHARE_BUTTON_CLICKED: 'SOCIAL_SHARE_BUTTON_CLICKED',
  TEXT_DOWNLOAD_BUTTON_CLICKED: 'TEXT_DOWNLOAD_BUTTON_CLICKED',
  LOVE_BUTTON_CLICKED: 'LOVE_BUTTON_CLICKED',
  //bi Only
  DOWNLOAD_BUTTON_CLICKED: 'DOWNLOAD_BUTTON_CLICKED',
  FULLSCREEN_LOADED: 'FULLSCREEN_LOADED'
};
export default FULLSCREEN_EVENTS;